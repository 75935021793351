import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../PrintInvoicePage.css";
import api from "../services/api.service";
import Loader from "../common/Loader";

const PrintInvoicePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { user, context } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState(null);

  const hasNavigated = useRef(false); // To prevent multiple navigations

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const handlePrint = useCallback(
    (data) => {
      return new Promise((resolve) => {
        document.title = `Receipt: #${data.order_number} Vendor: ${data.vendor.name} for ${data.user.name}`;

        const navigateBack = () => {
          if (!hasNavigated.current) {
            hasNavigated.current = true;
            setTimeout(() => {
              resolve();
              navigate(-1);
              window.removeEventListener("afterprint", afterPrintHandler);
              // window.removeEventListener("focus", focusHandler);
            }, 10000);
          }
        };

        const afterPrintHandler = () => {
          navigateBack();
        };

        // const focusHandler = () => {
        //   // Sometimes 'afterprint' might not fire, so use 'focus' as a fallback
        //   navigateBack();
        // };

        window.addEventListener("afterprint", afterPrintHandler);
        // window.addEventListener("focus", focusHandler);

        // Trigger print
        setTimeout(() => {
          if (isMobile()) {
            window.print();
          } else {
            try {
              document.execCommand("print", false, null);
            } catch (error) {
              window.print();
            }
          }
        }, 3000);
      });
    },
    [navigate]
  );

  // Fetch Invoice Data
  useEffect(() => {
    api
      .get(`/orders/${queryParams.get("id")}`)
      .then((response) => {
        setInvoiceData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false); // Ensure loading state is updated on error
      });
  }, [queryParams, user, context, dispatch]);

  // Trigger Print after Data Load
  useEffect(() => {
    if (!isLoading && invoiceData) {
      handlePrint(invoiceData);
    }
  }, [isLoading, invoiceData, handlePrint]);

  return (
    <Fragment>
      <div id="appCapsule">
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="invoice-wrapper">
              <div className="invoice">
                <div className="invoice-header">
                  <div className="w-50 text-start d-flex align-items-center">
                    <img
                      src="/assets/img/logo.png"
                      alt="Logo"
                      className="logo"
                      width="150px"
                      height="auto"
                    />
                    <h3 className="pt-1">KwikQ</h3>
                  </div>
                  <div className="text-end fw-bold">
                    <p>
                      Order Number: {invoiceData.order_number}
                      <br />
                      Order Date:{" "}
                      {new Date(invoiceData.created_at).toLocaleString("en-ZA")}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between w-100 fw-bold buyer">
                  <p>
                    Buyer: {invoiceData.user.name}
                    <br />
                    Vendor: {invoiceData.vendor.name}
                  </p>
                </div>

                <div className="items">
                  <div className="order-items header">
                    <div className="item-name text-start">Item Name</div>
                    <div className="price text-center ">Price</div>
                    <div className="quantity text-center">Quantity</div>
                    <div className="item-total text-end">Total</div>
                  </div>
                  {invoiceData.order_items.data.map((item) => (
                    <div key={item.id} className="item py-1">
                      <div className="order-items">
                        <div className="item-name">
                          <span>
                            {item.variant.item.name} ({item.variant.name})
                          </span>
                          <br />
                          {item.item_options.data.map((option) => (
                            <span key={option.option.id}>
                              {option.option.name}
                              <br />
                            </span>
                          ))}
                        </div>
                        <div className="text-center price">
                          <span className="text-color">
                            {" "}
                            R {item.variant.price}
                          </span>
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              <span className="text-color">
                                {" "}
                                R {orderItem.option.price}
                              </span>
                              <br />
                            </span>
                          ))}
                        </div>
                        <div className="quantity text-center">
                          <span>{item.quantity}</span>
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              <span className="quantity">{item.quantity}</span>
                              <br />
                            </span>
                          ))}
                        </div>
                        <span className="item-total  text-end">
                          R{" "}
                          {parseFloat(
                            item.variant.price * item.quantity
                          ).toFixed(2)}
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              R{" "}
                              {(orderItem.option.price * item.quantity).toFixed(
                                2
                              )}
                              <br />
                            </span>
                          ))}
                        </span>
                        <div className="w-100 note">
                          {console.log(item)}
                          <p>
                            {" "}
                            {item.notes && (
                              <div>
                                <hr />
                                <b className="text-color ">
                                  {" "}
                                  Note: <br />
                                  {item.notes}
                                </b>
                                <hr />
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <p className="total">
                  Order Total:{" "}
                  <span className="text-color"> R {invoiceData.total} </span>
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default PrintInvoicePage;
