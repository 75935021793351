import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api.service";
import AppHeader from "../common/AppHeader";
import Loader from "../common/Loader";
import { fetchActiveQueuesAction } from "../redux/features/queues/queuesActions";
import { updateQueueItemState } from "../redux/features/queues/queuesSlice";
import TimeAgo from "react-timeago";
import NotificationsBar from "../common/NotificationsBar";
import ManageNotifications from "../common/ManageNotifications";
import Masonry from "react-masonry-css";
import OrderCollectionDelivery from "../components/OrderCollectionDelivery";

const QueuesPage = () => {
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [filter, setFilter] = useState(queryParams.get("filter") === "all" ? "" : "new");

  const { user, context } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const showHST = process.env.REACT_APP_HASHST === "true";
  const isLoading = useSelector((state) => state.queues.loading);
  const queues = useSelector((state) => state.queues.data);
  const [newQueues, setNewQueues] = useState([]);
  const [busyQueues, setBusyQueues] = useState([]);
  const [doneQueues, setDoneQueues] = useState([]);
  const [orders, setOrders] = useState([]);


  const navigate = useNavigate();

  const setIsLoading = useCallback(
    (value) => {
      dispatch({ type: "queues/setIsLoading", payload: value });
    },
    [dispatch]
  );

  var HstTotal = (props) => {

    let order = props.order;
    let symbol = props.symbol;
    return (<>
       {/* <div className="w-100 d-flex justify-content-between listview py-1 fw-bold py-2">
        <span className="fw-bold">Service fee</span>
        <span className="text-success">
          $ {1.00}
        </span>
      </div> */}
      <div className="w-100 d-flex justify-content-between listview py-1 fw-bold py-2">
        <span className="">HST</span>
        <span className="text-success">
          {symbol} {(+order.total * 0.13).toFixed(2)}
        </span>
      </div>
      <div className="w-100 d-flex justify-content-between listview py-1 fw-bold py-2">
        <span className="fw-bold">Total</span>
        <span className="text-success">
          {/* $ {((+order.total + 1) * 1.13).toFixed(2)} */}
          {symbol} {((+order.total) * 1.13).toFixed(2)}
        </span>
      </div>
    </>
    )
  };

  var NoHstTotal = (props) => {
    let order = props.order;
    let symbol = props.symbol;
    return (<>
       {/* <div className="w-100 d-flex justify-content-between listview py-1 fw-bold py-2">
        <span className="fw-bold">Service fee</span>
        <span className="text-success">
          $ {(+1).toFixed(2)}
        </span>
      </div> */}
      <div className="w-100 d-flex justify-content-between listview py-1 fw-bold py-2">
        <span className="fw-bold">Total</span>
        <span className="text-success">
          {/* $ {(+order.total + 1.00).toFixed(2)} */}
          {symbol} {(+order.total).toFixed(2)}
        </span>
      </div>
    </>
    )
  };

  const getNextQueueStateLabel = (queueState) => {
    switch (queueState) {
      case "new":
        return "Pick";
      case "busy":
        return "Done";
      default:
        return "Collected";
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `.offcanvas-backdrop { display: none !important; }`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    dispatch(fetchActiveQueuesAction(context));

    api
      .get("/me", { params: { context } })
      .then((response) => {
        setOrders(response.data.orders.data);
        setIsLoading(true);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [user, navigate, context, dispatch, setIsLoading]);

  useEffect(() => {
    const sortedQueues = [...queues].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    setNewQueues(sortedQueues.filter((queue) => queue.state === "new"));
    setBusyQueues(sortedQueues.filter((queue) => queue.state === "busy"));
    setDoneQueues(sortedQueues.filter((queue) => queue.state === "done"));

  }, [queues, orders]);

  const handleOnNextQueueState = (e, queue) => {
    let queueState = null;

    switch (queue.state) {
      case "new":
        queueState = "busy";
        break;
      case "busy":
        queueState = "done";
        break;
      // case "problem":
      //   queueState = "problem";
      //   break;
      default:
        queueState = "collected";
        break;
    }

    api
      .put(`/queues/${queue.id}`, {
        state: queueState,
      })
      .then((response) => {
        dispatch(updateQueueItemState(response.data.data));

        if (queueState === "collected") {
          setFilter("done");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const totalLength =
    (newQueues ? newQueues.length : 0) +
    (busyQueues ? busyQueues.length : 0) +
    (doneQueues ? doneQueues.length : 0);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  const stateOrder = {
    new: 3,
    busy: 2,
    done: 1,
  };

  return (
    <Fragment>
      <AppHeader name="Orders"></AppHeader>

      <div id="appCapsule">
        {isLoading && !queues ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="header-block">
              <div className="header-inner"></div>
            </div>
            <div className="header-text text-center">
              <h1>Orders</h1>
              <div className="header-description text-center">
                <hr />
              </div>
            </div>
            <ManageNotifications />

            {(newQueues && newQueues.length > 0) ||
              (busyQueues && busyQueues.length > 0) ||
              (doneQueues && doneQueues.length > 0) ? (
              <div>
                <div className="row w-100 rounded shadow py-4 px-2">
                  <ul className="nav nav-tabs d-flex flex-row justify-content-around queues-nav">

                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  text-center p-2 w-100${filter === "new" ? " active " : "bg-secondary"
                          }`}
                        onClick={() => setFilter("new")}
                      >
                        New
                        <br />
                        <span className="badge badge-dark ">
                          {newQueues.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  text-center w-100 p-2  ${filter === "busy" ? " active " : "bg-secondary"
                          }`}
                        onClick={() => setFilter("busy")}
                      >
                        Busy
                        <br />
                        <span className="badge badge-dark ">
                          {busyQueues.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  p-2 w-100 ${filter === "done" ? "  active" : "bg-secondary"
                          }`}
                        onClick={() => setFilter("done")}
                      >
                        Done
                        <br />
                        <span className="badge badge-dark ">
                          {doneQueues.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link text-center w-100 p-2 ${filter === "" ? " active " : "bg-secondary"
                          }`}
                        onClick={() => setFilter("")}
                      >
                        All
                        <br />
                        <span className="badge badge-dark">{totalLength}</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="text-center p-1 mb-n5">
                  {" "}
                  <h1>
                    {filter === ""
                      ? "All Orders"
                      : filter === "new"
                        ? "New Orders"
                        : filter === "busy"
                          ? "Orders in Progress"
                          : "Completed Orders"}
                  </h1>
                </div>
              </div>
            ) : (
              <div>
                <div className="text-center p-3 pb-4 w-100 fs-3 fw-bold text-muted">
                  You have no orders in the queue right now
                </div>{" "}
                <hr />{" "}
              </div>
            )}
            <div className="w-100">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {[...queues]
                  .sort((a, b) => {
                    if (stateOrder[a.state] > stateOrder[b.state]) {
                      return -1;
                    }
                    if (stateOrder[a.state] < stateOrder[b.state]) {
                      return 1;
                    }

                    // If states are equal, compare based on the creation date
                    return new Date(b.created_at) - new Date(a.created_at);
                  })
                  .filter((queue) => (filter ? queue.state === filter : queue))
                  .map((queue) => {
                    return (
                      
                      <div
                        className="queue-card content-block"
                        key={`queue-${queue.id}`}
                      >
                       {/* {context.type == "Vendor"? (<div className="contact-button">Contact</div>):null}
                       {context.type == "User"? (<div className="contact-problem-button">Issue with order, please contact vendor 
                       <br/>
                       {queue.order.vendor.name}</div>):null} */}
                        <div className="card-body p-0">
                          <div
                            className={`rounded-full pill-status  text-capitalize ${queue.state === "new"
                              ? "bg-custom-blue"
                              : queue.state === "busy"
                                ? "bg-custom-orange"
                                : "bg-custom-green"
                              }`}
                          >
                            &nbsp; {queue.state} &nbsp;
                          </div>

                          <div className="fs-3 mt-3 pb-2 text-body">
                            ORDER #{queue.order.order_number}
                            <div className="d-none">{queue.order.user.name}</div>
                          </div>
                          {context.type === "Vendor" ? (
                            <div
                              className={`card-header p-0 pb-2 ${(new Date() - new Date(queue.created_at)) /
                                1000 /
                                60 >
                                20
                                ? "text-danger"
                                : "text-success"
                                }`}
                            >
                              {" "}
                              <TimeAgo date={queue.created_at} units="second" />
                            </div>
                          ) : (
                            <p className="card-header p-0 pb-2">
                              {queue.order.vendor.name}
                            </p>
                          )}

                          {/* <div className="fw-bold ">Items</div> */}
                          {queue.order.order_items &&
                            queue.order.order_items.data &&
                            queue.order.order_items.data.length > 0 ? (
                            <ul className="listview flush pt-1">
                              {queue.order.order_items.data.map(
                                (order_item) => {
                                  return (
                                    <li
                                      className="px-3"
                                      key={`order-item-${order_item.id}`}
                                    >
                                      <div className="row mb-1 ">
                                        <div className="col">
                                          <span>
                                            {order_item.quantity} x{" "}
                                            {order_item.variant.item.name}
                                          </span>
                                          <br />
                                          <div className="px-4 pt-1">{order_item.variant.name}</div>

                                        </div>
                                      </div>

                                      {order_item.item_options &&
                                        order_item.item_options.data &&
                                        order_item.item_options.data.length > 0
                                        ? order_item.item_options.data.map(
                                          (item_option) => {
                                            return (
                                              <div
                                                className="row mb-1"
                                                key={`item-option-${item_option.id}`}
                                              >
                                                <div className="col">
                                                  <span className="px-2 text-success">
                                                    <i className="bi bi-plus-circle"></i>{" "}
                                                    {item_option.option.name}{" "}
                                                    {/* <b>
                                                          R{item_option.price}
                                                        </b> */}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                        : null}
                                      {order_item.notes && (
                                        <div className="row px-4 mb-2">
                                          <div className="col pt-1">
                                            <b className="fw-bold pb-2 "> Note: </b><br />
                                            {order_item.notes}
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          ) : null}
                        </div>
                        {context.type === "Vendor" && queue.state !== "done" ? (
                          <div className=" w-100 d-flex  flex-column">
                            <div className="w-100 d-flex  flex-row align-content-between">
                              <div className="w-100 mr-1 text-end">
                                <Link
                                  to={`/order?id=${queue.order.id}&state=${queue.state}`}
                                  className="btn btn-sm btn-primary mx-3"
                                >
                                  View Order
                                </Link>

                                <button
                                  className="btn btn-sm btn-dark "
                                  onClick={(e) =>
                                    handleOnNextQueueState(e, queue)
                                  }
                                >
                                  {getNextQueueStateLabel(queue.state)}
                                </button>
                              </div>
                            </div>
                            <div className="w-100 text-center pt-1">
                              <span>
                                <span>
                                  DATE:{" "}
                                  {new Date(
                                    queue.created_at
                                  ).toLocaleDateString("en-ZA")}
                                </span>{" "}
                                {new Date(queue.created_at).toLocaleTimeString(
                                  "en-ZA"
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          context.type === "Vendor" && (
                            <div className=" d-flex  flex-column">
                              <div
                                className={`${getNextQueueStateLabel(queue.state) ===
                                  "Collected"
                                  ? "w-100"
                                  : "w-50"
                                  }`}
                              >
                                <div className="w-100 text-end">
                                  <Link
                                    to={`/order?id=${queue.order.id}&state=collected`}
                                    className="btn btn-sm btn-primary"
                                  >
                                    View Order
                                  </Link>
                                  <button
                                  className="btn btn-sm btn-dark "
                                  onClick={(e) =>
                                    handleOnNextQueueState(e, queue)
                                  }
                                >
                                   {getNextQueueStateLabel(queue.state)}
                                </button>
                                </div>


                                <div className="w-100 text-center pt-1">
                                  <span>
                                    <span>
                                      DATE:{" "}
                                      {new Date(
                                        queue.created_at
                                      ).toLocaleDateString("en-ZA")}
                                    </span>{" "}
                                    {new Date(
                                      queue.created_at
                                    ).toLocaleTimeString("en-ZA")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}

                        {context.type === "User" && (
                          <div className="w-100">
                            {showHST ? <HstTotal order={queue.order} symbol={queue.order.currency_symbol} /> : <NoHstTotal order={queue.order} symbol={queue.order.currency_symbol}/>}
                            {queue.state === "done" ? (
                              <div className="text-sm text-muted">
                                {
                                  queue.order.delivery_method === 'deliver' ?
                                    'Ready to be delivered!'
                                    :
                                    'Ready to be collected!'
                                }
                                <button
                                  className="btn btn-sm btn-primary float-end mt-3"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#queues-${queue.id}`}
                                >
                                  {
                                    queue.order.delivery_method === 'deliver' ?
                                      'Delivered'
                                      :
                                      'Collect'
                                  }
                                </button>
                                <div
                                  className="modal fade dialogbox"
                                  id={`queues-${queue.id}`}
                                  data-bs-backdrop="static"
                                  tabindex="-1"
                                  role="dialog"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">
                                          {queue.order.vendor.name}
                                        </h5>
                                      </div>
                                      <div className="modal-body">
                                        <h1>
                                          Order #{queue.order.order_number}
                                        </h1>
                                      </div>
                                      <div className="modal-footer">
                                        <div className="btn-inline">
                                          <a
                                            href="!#"
                                            className="btn btn-text-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            CLOSE
                                          </a>
                                          <a
                                            href="!#"
                                            className="btn btn-text-primary"
                                            onClick={(e) => {
                                              handleOnNextQueueState(e, queue);
                                            }}
                                            data-bs-dismiss="modal"
                                          >
                                            OK
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="text-sm text-muted">
                                  You are {queue.position_in_queue} in the queue
                                  for this vendor
                                </div>

                                <div className="  float-end ">
                                  <Link
                                    to={`/order/print?id=${queue.order.id}`}
                                    className="btn btn-sm btn-primary mx-3"
                                  >
                                    Print
                                  </Link>
                                  <Link
                                    to={`/order?id=${queue.order.id}&state=${queue.state}&queue_id=${queue.id}`}
                                    className="btn btn-sm btn-dark "
                                  >
                                    View Order
                                  </Link>
                                </div>
                              </div>
                            )}
                            <div className="text-sm text-muted">
                              Added{" "}
                              <TimeAgo date={queue.created_at} units="second" />
                            </div>
                            <small>
                              Created At:{" "}
                              {new Date(queue.created_at).toLocaleString(
                                "en-GB"
                              )}
                            </small>
                          </div>
                        )}

                        <OrderCollectionDelivery order={queue.order} disabled={true} ></OrderCollectionDelivery>
                      </div>
                    );
                  })}
              </Masonry>
              {filter === "" && orders.length > 0 && (
                <div className="container-fluid my-3">
                  <div id="completed" className="w-100 p-2 text-center">
                    <h1>Previous Orders</h1>
                  </div>
                  <div className="row rg-5">
                    {orders.filter(order => order.state === 'completed').map((order) => {
                      return (
                        <div
                          className={` col-md-6 col-lg-4 col-sm-12  p-1`}
                          key={`previous-order-${order.id}`}
                        >

                          <div className="card p-0 rounded shadow h-100">


                            <div
                              className="card-header queue-img"
                              style={{
                                backgroundImage: order.vendor?.image_url
                                  ? `url(${order.vendor.image_url})`
                                  : "none",
                              }}
                            >
                              <div className="w-100 text-end pb-2 pt-1">
                                <h5 className="fw-normal queue-text rounded-full p-1 d-inline  float-right bg-custom-white px-3 py-1 d-inline">
                                  Collected
                                </h5>
                              </div>
                              <div>
                                <h2 className="text-white queue-text">
                                  Order #{order.order_number}
                                </h2>
                                <h4 className="queue-text text-white">
                                  Your order has been collected.
                                </h4>
                              </div>
                            </div>
                            <div className="p-1 ">
                              <div className="card-body p-0">
                                <div className="w-100 pt-2">
                                  {context.type === "Vendor" ? (
                                    <span className="text-uppercase">
                                      {order.user.name}
                                    </span>
                                  ) : (
                                    <span className="fw-bold card-header p-0 pb-2 border-0">
                                      {order.vendor.name}
                                    </span>
                                  )}
                                  <br />
                                  <span>Order #{order.order_number}</span>

                                  <br />
                                  <span>
                                    Date:{" "}
                                    {new Date(order.created_at).toLocaleDateString(
                                      "en-ZA"
                                    )}
                                  </span><br />
                                  <span>
                                    Time:{" "}
                                    {new Date(order.created_at).toLocaleTimeString(
                                      "en-ZA"
                                    )}
                                  </span>
                                  <br />
                                </div>

                                <div className="card-header -100 p-0 pt-1">Items</div>
                                <div className="w-100"></div>
                                <div className="width-100">
                                  {order.order_items?.data &&
                                    order.order_items.data.length > 0 ? (
                                    <ul className="listview flush pt-1">
                                      {order.order_items.data.map(
                                        (order_item) => {
                                          return (
                                            <li
                                              className="py-3"
                                              key={`previous-order-item-${order_item.id}`}
                                            >
                                              <div className="row ">
                                                <div className="col">

                                                  <span>{order_item.quantity} x{" "}
                                                    {order_item.variant.item.name}
                                                  </span>
                                                  <div className="px-4 pt-1">
                                                    {order_item.variant.name}
                                                  </div>

                                                </div>
                                                <div className="col text-end text-success pt-2">
                                                  <b className="text-success">{order.currency_symbol} {order_item.total}</b>
                                                </div>
                                              </div>



                                              {order_item.item_options?.data &&
                                                order_item.item_options.data
                                                  .length > 0
                                                ? order_item.item_options.data.map(
                                                  (item_option) => {
                                                    return (
                                                      <div
                                                        className="row mb-1"
                                                        key={`previous-item-option-${item_option.id}`}
                                                      >
                                                        <div className="col">
                                                          <span className="px-2">
                                                            <i className="bi bi-plus-circle"></i>{" "}
                                                            {
                                                              item_option.option
                                                                .name
                                                            }{" "}
                                                            <small>
                                                            {order.currency_symbol}
                                                              {
                                                                item_option.price
                                                              }
                                                            </small>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                                : null}

                                              {order_item.notes && (
                                                <div className="row px-4 mb-2"><div className="col pt-2">
                                                  <b className="fw-bold pb-2 "> Note: </b>
                                                  <br />{order_item.notes}</div></div>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : null}
                                </div>
                              </div>
                              {showHST ? <HstTotal order={order} /> : <NoHstTotal order={order} />}
                              <div style={{ height: "70px" }}></div>
                              <div className="w-100 py-4 px-3 position-absolute bottom-0">
                                <div className=" text-muted w-100 text-end">
                                  <Link
                                    to={`/order/print?id=${order.id}`}

                                    className="btn btn-sm btn-primary mx-3"
                                  >
                                    Print
                                  </Link>


                                  <Link
                                    to={`/order?id=${order.id}&state=collected`}
                                    className="btn btn-sm btn-dark "
                                  >
                                    View Order
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )}
        <Footer />
      </div>
      <NotificationsBar />
      <BottomMenu />
      <SidebarPanel />
    </Fragment>
  );
};

export default QueuesPage;
