import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import NotificationsBar from "../common/NotificationsBar";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api.service";
import AppHeader from "../common/AppHeader";
import Loader from "../common/Loader";
import BottomMenu from "../common/BottomMenu";
import { clearActiveVenue } from "../redux/features/app/globalSlice";
import context from "react-bootstrap/esm/AccordionContext";

const VenuesPage = () => {
  const { user, context } = useSelector((state) => state.auth);
  const staging = process.env.REACT_APP_STAGING === 'true';
  const sandbox = process.env.REACT_APP_SANDBOX === 'true';
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    if (context.type === 'Vendor') {
      navigate("/queues");
    }
    api
      .get(`/venues`, {params: {staging: staging}})
      .then((response) => {
        setVenues(response.data.data);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);

        setIsLoading(false);
      });
  }, [navigate, user]);

  useEffect(() => {
    dispatch(clearActiveVenue());
  }, [dispatch]);

  return (
    
    <Fragment >
      
      <AppHeader name="Venues"></AppHeader>
      <div id="appCapsule ">
      <div className="venue-block">
            <div
              className="venue-inner"
           ></div>
          </div>
          <div className="header-text text-center">
              {staging?<h1>Venues STAGING!!!!</h1>:<h1>Venues {sandbox?<span> SANDBOX </span>:null}</h1>}
              <div className="header-description text-center">
                <hr />
              </div>
            </div>

        
        <div className="markets-body alt-bg ">
          {isLoading ? (
            <Loader />
          ) : (
            
            venues.map((venue) => {
              return (
                <div className="block-card content-block my-3" key={venue.id}>
                  <Link to={`/venues/${venue.id}/vendors`}>      
                    <div>
                      <div className="block-inner "
                        style={{ backgroundImage: `url(${venue.image_url})` }}
                      ></div>
                      <div className="w-100 text-center block-text" >
                        <h3>{venue.name}</h3>
                        <hr className="" />
                        <p>{venue.blurb}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>
      
      <Footer />
     
      <BottomMenu name="Venues" />
      <NotificationsBar />
      <SidebarPanel />
      <NotificationsBar />
    </Fragment>
  );
};

export default VenuesPage;
