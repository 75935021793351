import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { loginAction } from '../redux/features/auth/authActions';
import { loginWithPhoneAction } from '../redux/features/auth/authActions';
import { setToast } from '../redux/features/app/globalSlice';
import { setAuthModalAs } from '../redux/features/auth/authSlice';
let usePhone = process.env.REACT_APP_USEPHONE??false;
const Login = () => {
    const { loading, error, showAuthModal } = useSelector(
        (state) => state.auth
    );
    
    const dispatch = useDispatch()  
    
    const { register, handleSubmit } = useForm()

    const onSubmit = (data) => {
        if(usePhone && data.phone){
            dispatch(loginWithPhoneAction(data))
        }else{
           dispatch(loginAction(data))
        }
    }

    useEffect(() => {
        if (error) {
            var toast = {
                content: error,
                color: 'danger'
            };
            dispatch(setToast(toast));
        }
    }, [error, dispatch]);

    return (
        
        <div className="login-background position-relative">
           
           <Link to="/" className="rounded-full btn btn-primary text-white m-1 shadow-lg" >
             <ion-icon name="chevron-back-outline"></ion-icon> 
                    </Link>
                
        <div className="login-form " >
            <div className="section ">
                <img src="/assets/img/logo.png" alt="" className="form-image" />
            </div>
            <div className="section mt-1">
               
            </div>
            <div className=" mt-1 p-sm-5 p-md-3 absolute-bottom  shadow bg-white login-top-rounded fade-up  d-flex justify-content-center flex-column">
            <h1 >Login</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group boxed ">
                        <div className="input-wrapper ">
                            <input type="email" className="form-control rounded-full" { ...register('email')} placeholder="Email address" required />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                     {usePhone == true ? <div className="form-group boxed ">
                        <div className="input-wrapper ">
                            <input type="tel" className="form-control rounded-full" { ...register('phone')} placeholder="Phone number" required />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        </div>: null}  
                    <div className="form-group boxed mb-2">
                        <div className="input-wrapper">
                            <input type="password" className="form-control rounded-full" { ...register('password')} placeholder="Password" autoComplete="off" />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>

               
                        <div>
                        <div className="form-button-group mb-3">
                        <button type="submit" className="btn  rounded-full btn-dark alt-bg-color btn-block btn-lg border-0" disabled={loading} >Log in</button>
                    </div>
                          {showAuthModal ? 
                            <div className="form-button-group">
                            <Link className="btn btn-dark  rounded-full btn alt-bg-color -block btn-lg w-100  border-0" to="/" onClick={(e) => {e.preventDefault(); dispatch(setAuthModalAs('Register'))}} >Register</Link>
                            </div>
                            :
                            <div className="form-button-group mb-3">
                            <Link className="btn btn-dark rounded-full alt-bg-color btn-block btn-lg w-100  border-0" to="/register">Register</Link>
                            </div>
                          }
                        </div>
                        <div>
                          {showAuthModal ? 
                            <Link to="/" className="text-muted mt-3" onClick={(e) => {e.preventDefault(); dispatch(setAuthModalAs('ForgotPassword'))}} >Forgot Password</Link>
                            :
                            <Link to="/forgot-password" className="text-muted mt-3">Forgot Password?</Link>   
                          }
                        </div>
                 
        
                </form>
              
          
        </div>
       
                  
            </div>
        </div>
        
    );
}

export default Login;